define([], () => {
  const bmiCalculatorV2 = () => {
    const component = {};

    const _config = {
      attrib: {
        bmiResultVal: 'data-bmi-result',
        defaultUnit: 'data-default-unit',
        nextButtonLink: 'data-next-button-link'
      },
      selectors:{
        metricHeightInput:'[data-js-element=height-cm-input]',
        imperialHeightFeetInput:'[data-js-element=height-ft-input]',
        imperialHeightInchesInput:'[data-js-element=height-in-input]',
        heightRangeSlider:'[data-js-element=height-range-slider]',
        metricWeightInput:'[data-js-element=weight-kg-input]',
        imperialWeightInput:'[data-js-element=weight-lbs-input]',
        imperialWeightStoneInput:'[data-js-element=weight-st-input]',
        imperialWeightPoundsInput:'[data-js-element=weight-lb-input]',
        unitToggle: '[data-js-element=unit-toggle]',
        imperialFields: '[data-js-element=imperial-element]',
        metricFields: '[data-js-element=metric-element]',
        bmiResultSlider: '[data-bmi-result]',
        underWeightBand: '[data-js-element=under-weight-band]',
        normalWeightBand: '[data-js-element=normal-weight-band]',
        overWeightBand: '[data-js-element=over-weight-band]',
        obeseWeightBand: '[data-js-element=obese-weight-band]',
        nextButton: '[data-js-element=next-button]'
      },
      classes: {
        hideClass: 'bmiCalculatorV2_hide-element',
        disabledClass: 'disabled'
      },
      settings: {
        minHeight: 130,
        maxHeight: 200,
        startingHeight: 130,
        minWeight: 30,
        maxWeight: 150,
        startingWeight: 30,
        heightRangeInFeet: {from: 4, to: 6},
        heightRangeInInches: {from: 0,to: 11},
        weightRangeInPounds: {from: 0, to: 13},
        weightRangeInStones: {from: 4, to: 23},
        toggleState: false
      }
    };

    const _heightInputObservers = [];
    const _weightInputObservers = [];
    const _toggleObservers = [];

    const _init = (element) => {
      component.element = element;

      component.elements = {
        metricHeightInput: element.querySelector(component.config.selectors.metricHeightInput),
        imperialHeightFeetInput: element.querySelector(component.config.selectors.imperialHeightFeetInput),
        imperialHeightInchesInput: element.querySelector(component.config.selectors.imperialHeightInchesInput),
        heightRangeSlider: element.querySelector(component.config.selectors.heightRangeSlider),
        metricWeightInput: element.querySelector(component.config.selectors.metricWeightInput),
        imperialWeightInput: element.querySelector(component.config.selectors.imperialWeightInput),
        imperialWeightStoneInput: element.querySelector(component.config.selectors.imperialWeightStoneInput),
        imperialWeightPoundsInput: element.querySelector(component.config.selectors.imperialWeightPoundsInput),
        unitToggle: element.querySelector(component.config.selectors.unitToggle),
        metricElements: [...component.element.querySelectorAll(component.config.selectors.metricFields)],
        imperialElements: [...component.element.querySelectorAll(component.config.selectors.imperialFields)],
        bmiResultSlider: component.element.querySelector(component.config.selectors.bmiResultSlider),
        underWeightBand: [...component.element.querySelectorAll(component.config.selectors.underWeightBand)],
        normalWeightBand: [...component.element.querySelectorAll(component.config.selectors.normalWeightBand)],
        overWeightBand: [...component.element.querySelectorAll(component.config.selectors.overWeightBand)],
        obeseWeightBand: [...component.element.querySelectorAll(component.config.selectors.obeseWeightBand)],
        nextButton: element.querySelector(component.config.selectors.nextButton),
      };

      component.config.settings.toggleState = component.elements.unitToggle.getAttribute(component.config.attrib.defaultUnit) === 'false';

      component.subscribe(component.updateMetricHeight, component.heightInputObservers);
      component.subscribe(component.updateImperialHeight, component.heightInputObservers);
      component.subscribe(component.updateMetricWeight, component.weightInputObservers);
      component.subscribe(component.updateImperialWeight, component.weightInputObservers);
      component.subscribe(component.updateAltImperialWeight, component.weightInputObservers);
      component.subscribe(component.handleToggle, component.toggleObservers);

      component.populateMetric();
      component.populateImperial();
      component.heightListener();
      component.weightListener();
      component.listenForToggle();
      component.observeBmiResult();

      component.notify(component.config.settings.startingHeight, component.heightInputObservers);
      component.notify(component.config.settings.startingWeight, component.weightInputObservers);
      component.notify(component.config.settings.toggleState, component.toggleObservers);

      return component;
    };

    const _subscribe = (action, observers) => {
      observers.push(action);
    };

    const _notify = (data, observers) => {
      observers.forEach(observer => observer(data));
    };

    const _range = (start, end) => {
      if(start === end) return [start];
      return [start, ...component.range(start + 1, end)];
    };

    const _updateMetricHeight = (data) => {
      let value = component.validateInput(data, component.config.settings.minHeight, component.config.settings.maxHeight);
      component.elements.metricHeightInput.value = value;
      component.calculateBmi(component.elements.metricWeightInput.value, component.elements.metricHeightInput.value);
    };

    const _populateMetric = () => {
      const heightRange = component.range(component.config.settings.minHeight, component.config.settings.maxHeight);
      const heightSelector = component.elements.metricHeightInput;

      component.arrayToSelect(heightRange, heightSelector);

      const weightRange = component.range(component.config.settings.minWeight, component.config.settings.maxWeight);
      const weightSelector = component.elements.metricWeightInput;

      component.arrayToSelect(weightRange, weightSelector);
    };

    const _populateImperial = () => {
      const heightRangeInFeet = component.range(component.config.settings.heightRangeInFeet.from, component.config.settings.heightRangeInFeet.to);
      const heightRangeInInches = component.range(component.config.settings.heightRangeInInches.from, component.config.settings.heightRangeInInches.to);

      const heightInFeetSelector = component.elements.imperialHeightFeetInput;
      const heightInInchesSelector = component.elements.imperialHeightInchesInput;

      component.arrayToSelect(heightRangeInFeet, heightInFeetSelector);
      component.arrayToSelect(heightRangeInInches, heightInInchesSelector);

      const weightRangeInPounds = component.range(component.config.settings.weightRangeInPounds.from, component.config.settings.weightRangeInPounds.to);
      const weightRangeInStones = component.range(component.config.settings.weightRangeInStones.from, component.config.settings.weightRangeInStones.to);

      const weightInPoundsSelector = component.elements.imperialWeightPoundsInput;
      const weightInStoneSelector = component.elements.imperialWeightStoneInput;

      component.arrayToSelect(weightRangeInPounds, weightInPoundsSelector);
      component.arrayToSelect(weightRangeInStones, weightInStoneSelector);
    };

    const _arrayToSelect = (optionsList, selectTag) => {
      optionsList.forEach(function(item, index, array) {
        var opt = document.createElement("option");
        opt.text = item;
        opt.value = item;
        selectTag.add(opt);
      });
    }

    const _updateImperialHeight = (data) => {
      let value = component.validateInput(data, component.config.settings.minHeight, component.config.settings.maxHeight);
      const {feet, inches} = component.convertHeightToImperial(value);
      component.elements.imperialHeightFeetInput.value = feet;
      component.elements.imperialHeightInchesInput.value = inches;
    };

    const _updateMetricWeight = (data) => {
      let value = component.validateInput(data, component.config.settings.minWeight, component.config.settings.maxWeight);
      component.elements.metricWeightInput.value = value;
      component.calculateBmi(component.elements.metricWeightInput.value, component.elements.metricHeightInput.value);
    };

    const _updateImperialWeight = (data) => {
      let value = component.validateInput(data, component.config.settings.minWeight, component.config.settings.maxWeight);
      component.elements.imperialWeightInput.value = component.convertWeightToImperial(value);
    };

    const _updateAltImperialWeight = (data) => {
      let value = component.validateInput(data, component.config.settings.minWeight, component.config.settings.maxWeight);
      const {stones, pounds} = component.convertWeightToAltImperial(value);
      component.elements.imperialWeightStoneInput.value = stones;
      component.elements.imperialWeightPoundsInput.value = pounds;
    };

    const _handleToggle = (data) => {
      component.elements.metricElements.forEach((element) => {
        element.classList.add(component.config.classes.hideClass);
      });
      component.elements.imperialElements.forEach((element) => {
        element.classList.add(component.config.classes.hideClass);
      });
      if (data === false){
        component.elements.imperialElements.forEach((element) => {
          element.classList.remove(component.config.classes.hideClass);
        });
      } else {
        component.elements.metricElements.forEach((element) => {
          element.classList.remove(component.config.classes.hideClass);
        });
      }
      component.elements.unitToggle.checked = component.config.settings.toggleState;
    };

    const _heightListener = () => {
      component.elements.metricHeightInput.addEventListener("change" , (e)=> {
        component.notify(e.target.value, component.heightInputObservers);
      });
      component.elements.imperialHeightFeetInput.addEventListener("change" , (e)=> {
        let metricHeight = component.convertHeightToMetric(e.target.value, component.elements.imperialHeightInchesInput.value);
        component.notify(metricHeight, component.heightInputObservers);
      });
      component.elements.imperialHeightInchesInput.addEventListener("change" , (e)=> {
        let metricHeight = component.convertHeightToMetric(component.elements.imperialHeightFeetInput.value, e.target.value);
        component.notify(metricHeight, component.heightInputObservers);
      });
    };

    const _weightListener = () => {
      component.elements.metricWeightInput.addEventListener("change" , (e)=> {
        component.notify(e.target.value, component.weightInputObservers);
      });
      component.elements.imperialWeightInput.addEventListener("change" , (e)=> {
        let metricWeight = component.convertWeightToMetric(e.target.value);
        component.notify(metricWeight, component.weightInputObservers);
      });
      component.elements.imperialWeightStoneInput.addEventListener("change" , (e)=> {
        let metricWeight = component.convertAltImperialWeightToMetric(e.target.value, component.elements.imperialWeightPoundsInput.value);
        component.notify(metricWeight, component.weightInputObservers);
      });
      component.elements.imperialWeightPoundsInput.addEventListener("change" , (e)=> {
        let metricWeight = component.convertAltImperialWeightToMetric(component.elements.imperialWeightStoneInput.value, e.target.value);
        component.notify(metricWeight, component.weightInputObservers);
      });
    };

    const _listenForToggle = () => {
      component.elements.unitToggle.addEventListener("click", () => {
        component.config.settings.toggleState = !component.config.settings.toggleState;
        component.notify(component.config.settings.toggleState, component.toggleObservers);
      });
    };

    const _validateInput = (value, min, max) => {
      let result;
      if (value < min) {
        result = min;
      } else if (value > max) {
        result = max;
      } else {
        result = value;
      }
      return result;
    };

    const _convertHeightToImperial = (value) => {
      let totalInches = Math.round(Number(value)/2.54);
      let inches = totalInches%12;
      let feet = (totalInches-inches)/12;
      return {feet, inches};
    };

    const _convertHeightToMetric = (feet, inches) => {
      let exactFeet = (Number(feet) * 12) + Number(inches);
      return Math.round(exactFeet * 2.54);
    };

    const _convertWeightToMetric = (value) => {
      return Math.round(value * 0.453);
    };

    const _convertWeightToImperial = (value) => {
      return Math.round(value * 2.2);
    };

    const _convertWeightToAltImperial = (value) => {
      let actualStones = value/6.35;
      let stones = Math.floor(actualStones);
      let pounds = Math.floor((actualStones - stones) * 14);
      return {stones, pounds};
    };

    const _convertAltImperialWeightToMetric = (stones, pounds) => {
      let actualStones = Number(stones) + (Number(pounds)/14);
      return Math.round(actualStones*6.35);
    };

    const _observeBmiResult = () => {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          let bmiResult = mutation.target.getAttribute(component.config.attrib.bmiResultVal);
          component.calculateWeightBand(bmiResult);
        });
      });
      observer.observe(component.elements.bmiResultSlider, {
        attributes: true,
        attributeFilter: [component.config.attrib.bmiResultVal],
      });
    };

    const _calculateWeightBand = (val) => {
      if (val >= 7.5 && val <= 18.5) {
        component.updateNextButton(component.elements.underWeightBand);
      } else if (val > 18.5 && val <= 25) {
        component.updateNextButton(component.elements.normalWeightBand);
      } else if (val > 25 && val <= 30) {
        component.updateNextButton(component.elements.overWeightBand);
      } else if (val > 30) {
        component.updateNextButton(component.elements.obeseWeightBand);
      }
    };

    const _calculateBmi = (weight, height) => {
      let bmi = Math.round((weight / (Math.pow((height / 100), 2))) * 10) / 10;
      component.elements.bmiResultSlider.setAttribute(component.config.attrib.bmiResultVal, bmi);
    };

    const _updateNextButton = (selectedBand) => {
      let bandArr = [component.elements.underWeightBand, component.elements.normalWeightBand, component.elements.overWeightBand, component.elements.obeseWeightBand];

      bandArr.forEach((band) => {
        if (band === selectedBand) {
          let buttonHref = band.length && band[0].dataset.nextButtonLink;

          if (band.length !== 0) {
            component.elements.nextButton.setAttribute('href', buttonHref);
            component.elements.nextButton.classList.remove(component.config.classes.disabledClass);
          } else {
            component.elements.nextButton.classList.add(component.config.classes.disabledClass);
          }
        }
      });
    };

    component.config = _config;
    component.init = _init;
    component.subscribe = _subscribe;
    component.notify = _notify;
    component.range = _range;
    component.heightInputObservers = _heightInputObservers;
    component.updateMetricHeight = _updateMetricHeight;
    component.updateImperialHeight = _updateImperialHeight;
    component.populateMetric = _populateMetric;
    component.populateImperial = _populateImperial;
    component.arrayToSelect = _arrayToSelect;
    component.heightListener = _heightListener;
    component.weightInputObservers = _weightInputObservers;
    component.updateMetricWeight = _updateMetricWeight;
    component.updateImperialWeight = _updateImperialWeight;
    component.updateAltImperialWeight = _updateAltImperialWeight;
    component.weightListener = _weightListener;
    component.validateInput = _validateInput;
    component.convertHeightToImperial = _convertHeightToImperial;
    component.convertHeightToMetric = _convertHeightToMetric;
    component.convertWeightToImperial = _convertWeightToImperial;
    component.convertWeightToMetric = _convertWeightToMetric;
    component.convertWeightToAltImperial = _convertWeightToAltImperial;
    component.convertAltImperialWeightToMetric = _convertAltImperialWeightToMetric;
    component.listenForToggle = _listenForToggle;
    component.observeBmiResult = _observeBmiResult;
    component.calculateWeightBand = _calculateWeightBand;
    component.calculateBmi = _calculateBmi;
    component.updateNextButton = _updateNextButton;
    component.handleToggle = _handleToggle;
    component.toggleObservers = _toggleObservers;

    return component;
  };

  return bmiCalculatorV2;
});
